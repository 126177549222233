import api from './api.interface';
import BaseURL from '_Constants/url';

export const getRevenueReleaseData = async (id) => {
    try {
        const response = await api.get(`${BaseURL.API_REVENUE_RELEASE}/${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addRevenueReleaseData = async (payload) => {
    try {
        const response = await api.post(
            `${BaseURL.API_REVENUE_RELEASE}/add`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
