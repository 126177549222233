import { makeAutoObservable } from 'mobx';
import {
    getAIAnalytics,
    getCourseAnalytics,
    getMentorAnalytics,
    getPackageAnalytics,
    getPassAnalytics,
    getAllAccessAnalytics
} from '_Services/analytics.service';

class AnalyticsStore {
    aiAnalysis = [];
    mentorAnalysis = [];
    course = [];
    package = [];
    pass = [];
    allAccess = [];
    mentorAnalysisTime = [];
    salesTabIndex = 0;
    /* current value for dateFilter component used in analytics & sales section */
    dateFilter = 'TODAY';

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getAIAnalyticsAction({ startDate, endDate }) {
        try {
            const response = await getAIAnalytics(startDate, endDate);
            if (response.success) {
                this.aiAnalysis = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getMentorAnalyticsAction({ startDate, endDate }) {
        try {
            const mentorId = this.rootStore.auth.adminId;
            let queryObj = { startDate, endDate };
            this.rootStore.auth.isMentor &&
                (queryObj = { ...queryObj, mentorId });

            const response = await getMentorAnalytics(queryObj);

            if (response.success) {
                this.mentorAnalysis = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getCourseAnalyticsAction({ startDate, endDate }) {
        try {
            const mentorId = this.rootStore.auth.adminId;

            let queryObj = { startDate, endDate };
            this.rootStore.auth.isMentor &&
                (queryObj = { ...queryObj, mentorId });

            const response = await getCourseAnalytics(queryObj);

            if (response.success) {
                this.course = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getPackageAnalyticsAction({ startDate, endDate }) {
        try {
            const mentorID = this.rootStore.auth.adminId;

            const response = await getPackageAnalytics(startDate, endDate);

            /*  
                If admin is logged in, show the complete list, else filter 
                only those packs that the logged in mentor has their course
                included in. 
            */
            if (response.success) {
                let arr = response.data;
                if (this.rootStore.auth.isMentor) {
                    arr = arr.filter((arrObj) =>
                        arrObj.mentorArr.find((m) => m.mentorId === mentorID)
                    );
                }
                this.package = arr;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getPassAnalyticsAction({ startDate, endDate }) {
        try {
            const response = await getPassAnalytics(startDate, endDate);

            if (response.success) {
                this.pass = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getAllAccessAnalyticsAction({ startDate, endDate }) {
        try {
            const response = await getAllAccessAnalytics(startDate,endDate)

            if (response.success) {
                this.allAccess = response.data;
            }
        } catch (error) {
            console.error(error);
        }
    }

    changeDateFilter(val) {
        this.dateFilter = val;
    }
}

export default AnalyticsStore;
