import { makeAutoObservable } from 'mobx';
import {
    addPackage,
    getPackages,
    updatePackage,
    togglePackage,
    getFreePacks,
    upsertFreePacks,
} from '_Services/package.service';

class PackageStore {
    packages = [];
    freePkgObj = {
        title: '',
        description: '',
        trailers: [{ title: '', description: '', image: '', video: '' }],
    };
    packageUpdate = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getPackagesAction() {
        try {
            const response = await getPackages();
            if (response.success) {
                this.packages = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async addPackageAction(payload) {
        try {
            const response = await addPackage(payload);
            if (response.success) {
                this.packageUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updatePackageAction(payload) {
        try {
            const response = await updatePackage(payload);
            if (response.success) {
                this.packageUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async togglePackageAction(payload) {
        try {
            const response = await togglePackage(payload);
            if (response.success) {
                this.packageUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async getFreePacksAction() {
        try {
            const response = await getFreePacks();
            if (response.success && response.data) {
                this.freePkgObj = response.data;
            }
        } catch (err) {
            return;
        }
    }

    async upsertFreePacksAction(payload) {
        try {
            const response = await upsertFreePacks(payload);
            if (response.success) {
                this.packageUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    resetUpdatePackage() {
        this.packageUpdate = {};
    }
}

export default PackageStore;
