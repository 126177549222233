import api from './api.interface';
import BaseURL from '_Constants/url';

export const getWords = async () => {
    try {
        const response = await api.get(BaseURL.API_WORDS);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateWords = async (body) => {
    try {
        const response = await api.put(BaseURL.API_WORDS, body);
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ------ Video ------ */
export const addVideo = async (body) => {
    try {
        const response = await api.postt(`${BaseURL.API_WORDS}/video`, body);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateVideo = async (body) => {
    try {
        const response = await api.put(`${BaseURL.API_WORDS}/video`, body);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteVideo = async (body) => {
    try {
        const response = await api.delete(
            `${BaseURL.API_WORDS}/video?_id=${body?._id}&mentorId=${body?.mentorId}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
