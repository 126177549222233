import { configure } from 'mobx';
import adminUserStore from './admin-user.store';
import analysisStore from './analysis.store';
import analyticsStore from './analytics.store';
import authStore from './auth.store';
import couponStore from './coupon.store';
import courseStore from './course.store';
import introStore from './intro.store';
import lessonStore from './lesson.store';
import notificationStore from './notification.store';
import mentorStore from './mentor.store';
import packageStore from './package.store';
import passStore from './pass.store';
import toastStore from './toast.store';
import wordsStore from './words.store';
import constantStore from './constants.store';
import revenueStore from './revenue.store';
import bannerStore from './banner.store'
import allAccessStore from './all-access.store'

class RootStore {
    constructor() {
        configure({
            enforceActions: 'never',
            isolateGlobalState: true,
        });
        this.auth = new authStore(this);
        this.adminUser = new adminUserStore(this);
        this.allAccess = new allAccessStore(this);
        this.analysis = new analysisStore(this);
        this.analytics = new analyticsStore(this);
        this.banners = new bannerStore(this);
        this.coupon = new couponStore(this);
        this.course = new courseStore(this);
        this.intro = new introStore(this);
        this.lesson = new lessonStore(this);
        this.mentor = new mentorStore(this);
        this.notification = new notificationStore(this);
        this.package = new packageStore(this);
        this.pass = new passStore(this);
        this.toast = new toastStore(this);
        this.words = new wordsStore(this);
        this.constants = new constantStore(this);
        this.revenue = new revenueStore(this);
    }
}

export default new RootStore();
