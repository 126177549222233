import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import rootStore from '_Store';

const PREFIX = 'Loader';

const classes = {
    backdrop: `${PREFIX}-backdrop`,
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Loader = () => {
    return (
        <Root>
            <Backdrop
                className={classes.backdrop}
                open={rootStore.toast.loaderState}
            >
                <CircularProgress color="inherit" variant="indeterminate" />
            </Backdrop>
        </Root>
    );
};

export default observer(Loader);
