import { makeAutoObservable } from 'mobx';
import { getPassList, addPass, updatePass } from '_Services/pass.service';

class PassStore {
    numPages = 1;
    passList = [];
    passUpdate = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getPassesAction({ pageNum, numItems }) {
        try {
            const response = await getPassList({ pageNum, numItems });
            if (response.success) {
                this.numPages = response.data.numPages;
                this.passList = response.data.list;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async addPassAction(payload) {
        try {
            const response = await addPass(payload);
            if (response.success) {
                this.passUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updatePassAction(payload) {
        try {
            const response = await updatePass(payload);
            if (response.success) {
                this.passUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    // async togglePassAction(payload) {
    //     try {
    //         const response = await togglePassStatus(payload);
    //         if (response.success) {
    //             this.passUpdate = response;
    //         }
    //     } catch (err) {
    //         return;
    //     }
    // }

    resetUpdatePass() {
        this.passUpdate = {};
    }
}

export default PassStore;
