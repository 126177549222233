import { Fragment } from 'react';
import { Grid } from '@mui/material';
import LoginForm from './LoginForm';
import rootStore from '_Store';

const Login = () => {
    const submitForm = (values) => rootStore.auth.loginUserAction(values);

    return (
        <Fragment>
            <Grid
                container
                justifyContent="center"
                style={{ marginBottom: '100px' }}
            >
                <Grid item sm={5}>
                    <LoginForm submitForm={submitForm} />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default Login;
