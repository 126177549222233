import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import * as serviceWorker from './serviceWorker';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import App from './App';
import ability from '_Utils/Ability';
import { AbilityContext } from '_Utils/Can';
import rootStore from '_Store';
import theme from '_Utils/index.theme';
import '_Styles/index.css';

const Appl = observer(() => (
    <AbilityContext.Provider
        value={ability({
            isAdmin: rootStore.auth.isAdmin,
            isSupport: rootStore.auth.isSupport,
            isMentor: rootStore.auth.isMentor,
            isSales: rootStore.auth.isSales,
        })}
    >
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <Route path="/" component={App} />
                    </LocalizationProvider>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    </AbilityContext.Provider>
));

ReactDOM.render(<Appl />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
