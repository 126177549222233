import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import rootStore from '_Store';

export const RHFSubmitBtn = observer(({ btnText = 'Submit' }) => (
    <Button
        type="submit"
        variant="outlined"
        size="medium"
        color="success"
        disabled={rootStore.toast.loaderState}
    >
        {btnText}
    </Button>
));

export const RHFCancelBtn = observer(({ onClick }) => (
    <Button
        type="reset"
        variant="outlined"
        size="medium"
        color="error"
        onClick={onClick}
        disabled={rootStore.toast.loaderState}
    >
        Cancel
    </Button>
));

export const PublishBtn = ({ title, btnClick }) => (
    <Button
        style={{ ...styles.btn, ...styles.pubbtn }}
        onClick={() => btnClick()}
    >
        {title ?? 'Publish'}
    </Button>
);

export const UnPublishBtn = ({ title, btnClick }) => (
    <Button
        style={{ ...styles.btn, ...styles.unpubbtn }}
        onClick={() => btnClick()}
    >
        {title ?? 'Unpublish'}
    </Button>
);

export const CsvDownloadBtn = ({ title = 'Download', outlined = false }) => (
    <Button
        style={{
            ...styles.btn,
            backgroundColor: outlined ? 'white' : '#3fc495',
            color: outlined ? '#3fc495' : '#F2F2CB',
            border: outlined ? '1px solid' : 0,
            borderColor: outlined ? '#3fc495' : '#F2F2CB',
            height: 40,
            fontSize: 14,
            padding: '8px 12px',
            minWidth: 150,
        }}
    >
        <GetAppIcon style={styles.downloadIcon} />
        &nbsp;&nbsp;{title}
    </Button>
);

export const RegularBtn = ({
    text = 'Text',
    btnClick = () => null,
    bgColor = '#0080ff',
    outlined = false,
}) => (
    <Button
        style={{
            ...styles.btn,
            backgroundColor: outlined ? 'white' : bgColor,
            color: outlined ? bgColor : '#F2F2CB',
            border: outlined ? '1px solid' : 0,
            borderColor: outlined ? bgColor : '#F2F2CB',
        }}
        onClick={btnClick}
    >
        {text}
    </Button>
);

const styles = {
    btn: {
        textTransform: 'none',
        color: '#F2F2CB',
        minWidth: '90px',
    },
    pubbtn: {
        backgroundColor: '#367D5D',
    },
    unpubbtn: {
        backgroundColor: '#B50914',
    },
    downloadIcon: { fontSize: 20 },
};
