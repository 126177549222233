import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

export const DateTextField = (props) => {
    return <TextField {...props} variant="outlined" disabled={true} />;
};

export const DateTimeField = DateTextField;

export const RHFTextField = forwardRef((props, ref) => (
    <TextField
        inputRef={ref}
        variant="outlined"
        error={props.errors}
        helperText={props.helperText}
        fullWidth
        margin="dense"
        rows={props.rows}
        {...props}
    />
));

export const RHFNumberInput = forwardRef((props, ref) => (
    <TextField
        inputRef={ref}
        type="number"
        InputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            inputProps: {
                min: 0,
            },
        }}
        variant="outlined"
        error={props.errors}
        helperText={props.helperText}
        fullWidth
        margin="dense"
        {...props}
    />
));

export const RHFLoginField = forwardRef((props, ref) => (
    <TextField
        inputRef={ref}
        variant="outlined"
        error={props.errors}
        helperText={props.helperText}
        fullWidth
        margin="normal"
        {...props}
    />
));

export const MediaIptStyles = {
    iptField: {
        marginTop: '13px',
    },
    iptError: {
        color: '#f44336',
        fontSize: '12px',
    },
    imgView: {
        width: 100,
        height: 100,
    },
};
