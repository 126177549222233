import { Ability, AbilityBuilder } from '@casl/ability';

export default function defineAbilityFor({
    isAdmin = false,
    isMentor = false,
    isSupport = false,
    isSales = false,
}) {
    const { can, rules } = new AbilityBuilder();

    // link options in sidebar
    if (isAdmin) {
        can('view', [
            'Overview',
            'AdminUsers',
            'Mentors',
            'Courses',
            'Packages',
            'AllAccess',
            'Coupons',
            'Passes',
            'Products',
            'Notification',
            'Analytics',
            'Sales',
            'Dashboard',
            'SearchUser',
            'BugOrIssue',
            'FormResponses',
        ]);
    } else if (isSupport) {
        can('view', ['SearchUser', 'BugOrIssue', 'FormResponses']);
    } else if (isMentor) {
        can('view', ['Schedule', 'Dashboard', 'Schedule Manager', 'Sales']);
    } else if (isSales) {
        can('view', ['Analytics', 'Sales', 'Mentors']);
    }

    return new Ability(rules);
}
