import InfoIcon from '@mui/icons-material/Info';
import RedeemIcon from '@mui/icons-material/Redeem';
import CommentIcon from '@mui/icons-material/Comment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AdminIcon from '@mui/icons-material/AssignmentInd';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CouponIcon from '@mui/icons-material/LocalOffer';
import SalesIcon from '@mui/icons-material/MonetizationOn';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import PackageIcon from '@mui/icons-material/Widgets';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MentorIcon from '@mui/icons-material/People';
import BugReportIcon from '@mui/icons-material/BugReport';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InsightsIcon from '@mui/icons-material/Insights';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import BannerIcon from '@mui/icons-material/ViewCarousel';

export const sideBarOptions = [
    {
        linkTo: '/schedule',
        a: 'Schedule',
        text: 'My Schedule',
        icon: DateRangeIcon,
    },
    {
        linkTo: '/schedule-manager',
        a: 'Schedule Manager',
        text: 'Schedule Manager',
        icon: EventAvailableIcon,
    },
    {
        linkTo: '/admin-users',
        a: 'AdminUsers',
        text: 'Admin-Users',
        icon: AdminIcon,
    },

    {
        linkTo: '/analytics',
        a: 'Analytics',
        text: 'Analytics',
        icon: AnalyticsIcon,
    },
    { linkTo: '/sales', a: 'Sales', text: 'Sales', icon: SalesIcon },
    {
        linkTo: '/all-access',
        a: 'AllAccess',
        text: 'All Access',
        icon: InfoIcon,
    },
    {
        linkTo: '/passes',
        a: 'Passes',
        text: 'Passes',
        icon: ConfirmationNumberIcon,
    },
    {
        linkTo: '/coupons',
        a: 'Coupons',
        text: 'Coupons',
        icon: CouponIcon,
    },
    {
        linkTo: '/mentors',
        a: 'Mentors',
        text: 'Mentors',
        icon: MentorIcon,
    },
    {
        linkTo: '/courses',
        a: 'Courses',
        text: 'Courses',
        icon: SportsCricketIcon,
    },
    {
        linkTo: '/packages',
        a: 'Packages',
        text: 'Packages',
        icon: PackageIcon,
    },

    {
        linkTo: '/search-user',
        a: 'SearchUser',
        text: 'Search User',
        icon: PersonSearchIcon,
    },
    {
        linkTo: '/clickup-form',
        a: 'BugOrIssue',
        text: 'Raise Issue/Report a Bug',
        icon: BugReportIcon,
    },
    {
        linkTo: '/form-responses',
        a: 'FormResponses',
        text: 'Form Responses',
        icon: DynamicFormIcon,
    },
    {
        linkTo: '/notifications',
        a: 'Notification',
        text: 'Notification',
        icon: NotificationsActiveIcon,
    },
];

export const OverviewList = [
    {
        url: '/overview/words',
        text: 'Why Cricuru ?',
        icon: CommentIcon,
    },
    {
        url: '/overview/intro',
        text: 'Introduction',
        icon: InfoIcon,
    },
    { url: '/overview/banners', text: 'Banner', icon: BannerIcon },
    {
        url: '/overview/analysis-intro',
        text: 'Analysis Intro',
        icon: ShowChartIcon,
    },
    {
        url: '/overview/ai-analysis',
        text: 'AI Analysis',
        icon: InsightsIcon,
    },
    {
        url: '/overview/dial-a-coach',
        text: 'Dial A Coach',
        icon: VideoCameraFrontIcon,
    },
    {
        url: '/overview/free-packs',
        text: 'Free Packs',
        icon: RedeemIcon,
    },
];
