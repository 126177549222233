import Typography from '@mui/material/Typography';

const TypographyComponent = (props) => (
    <div>
        <Typography
            variant="subtitle1"
            gutterBottom
            onChange={props.handleChange}
            style={{ marginTop: '12px' }}
        >
            {props.text}
        </Typography>
    </div>
);

export default TypographyComponent;
