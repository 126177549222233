import { makeAutoObservable } from 'mobx';
import {
    getWords,
    updateWords,
    addVideo,
    updateVideo,
    deleteVideo,
} from '_Services/words.service';

class WordsStore {
    wordsUpdate = {};
    wordsObj = {
        title: '',
        desciption: '',
        coverImg: '',
    };

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getWordsAction() {
        try {
            const response = await getWords();
            if (response.success) {
                this.wordsObj = response.data;
            }
        } catch (err) {
            return;
        }
    }

    async updateWordsAction(payload) {
        try {
            const response = await updateWords(payload);
            if (response.success) {
                this.wordsUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async addVideoAction(payload) {
        try {
            const response = await addVideo(payload);
            if (response.success) {
                this.wordsUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateVideoAction(payload) {
        try {
            const response = await updateVideo(payload);
            if (response.success) {
                this.wordsUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async deleteVideoAction(payload) {
        try {
            const response = await deleteVideo(payload);
            if (response.success) {
                this.wordsUpdate = response;
                this.getWordsAction();
            }
        } catch (err) {
            return;
        }
    }

    resetUpdateVideo() {
        this.wordsUpdate = {};
    }
}

export default WordsStore;
