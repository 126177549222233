import Axios from 'axios';
import rootStore from '_Store';

const instance = Axios.create({
    // REACT_APP_API_URL will only be available inside the CI/CD pipeline
    // On local development this will be empty, and all request will be proxied via proxy field in package.jso
    // No need to set this manually
    baseURL: `${process.env.REACT_APP_API_URL ?? ''}/api`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
    },
});

instance.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        config.headers.Authorization = `Bearer ${localStorage.getItem('auth')}`;
        // config.headers.Accept = "*";
        rootStore.toast.enableLoader();
        return config;
    },
    (error) => {
        rootStore.toast.disableLoader();
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // Do something with response data
        rootStore.toast.disableLoader();
        if (response.config.method !== 'get') {
            rootStore.toast.showToast({
                method: 'success',
                message: response.data.message,
            });
            // show response to the user for non get request
        }
        return response;
    },
    (error) => {
        // Do something with response error
        rootStore.toast.disableLoader();
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.replace('/login');
        }
        if (error.response.config.method !== 'get') {
            // show response to the user for non get request
            rootStore.toast.showToast({
                method: 'error',
                message: error.response.data.message,
            });
        }
        return Promise.reject(error);
    }
);

export default instance;
