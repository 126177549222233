import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { RHFLoginField } from '_FormComps/TextField';

const PREFIX = 'LoginForm';

const classes = {
    '@global': `${PREFIX}-undefined`,
    paper: `${PREFIX}-paper`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    formBorder: `${PREFIX}-formBorder`,
    submit: `${PREFIX}-submit`,
    error: `${PREFIX}-error`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
    [`& .${classes.undefined}`]: {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },

    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px',
        top: '100%',
        '-ms-transform': 'translateY(-50%)',
        transform: 'translateY(1%)',
        padding: '30px 0px',
    },

    [`& .${classes.avatar}`]: {
        padding: '30px',
        margin: theme.spacing(1),
        backgroundColor: '#0080ff',
    },

    [`& .${classes.form}`]: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3.5),
        padding: '10px',
    },

    [`&.${classes.formBorder}`]: {
        border: '1px solid #ddd',
        borderRadius: '12px',
        boxShadow: '3px 10px 8px #aaaaaa',
    },

    [`& .${classes.submit}`]: {
        margin: theme.spacing(3, 0, 2),
        background: '#0080ff',
        color: '#fff',
        height: theme.spacing(5.5),
    },

    [`& .${classes.error}`]: {
        color: 'red',
    },
}));

const LoginForm = ({ submitForm }) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(submitForm);
        }
    };

    return (
        <StyledContainer
            component="main"
            maxWidth="xs"
            className={classes.formBorder}
        >
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Log In
                </Typography>

                <form
                    onSubmit={handleSubmit(submitForm)}
                    className={classes.form}
                >
                    <RHFLoginField
                        autoFocus={true}
                        autoComplete="email"
                        label="Email Address"
                        {...register('email', {
                            required: {
                                value: true,
                                message: 'Please enter your email',
                            },
                        })}
                        error={errors?.email ? true : false}
                        helperText={errors?.email?.message}
                        onKeyDown={(event) => onKeyDown(event)}
                    />

                    <RHFLoginField
                        autoComplete="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', {
                            required: {
                                value: true,
                                message: 'Please enter your password',
                            },
                            minLength: {
                                value: 8,
                                message:
                                    'Password length must be atleast 8 characters',
                            },
                        })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        size="large"
                                    >
                                        {showPassword ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={errors?.password ? true : false}
                        helperText={errors?.password?.message}
                        onKeyDown={(event) => onKeyDown(event)}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                    >
                        Log In
                    </Button>
                </form>
            </div>
        </StyledContainer>
    );
};

export default LoginForm;
