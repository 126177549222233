import api from './api.interface';
import BaseURL from '_Constants/url';

export const allAccessUpdate = async (payload) => {
    try{
        const response = await api.put(`${BaseURL.API_ALL_ACCESS}/update/${payload._id}`,payload.data)
        return response.data
    }catch(error){
        return error;
    }
};


export const allAccessAdd = async (payload) => {
    try{
        const response = await api.post(`${BaseURL.API_ALL_ACCESS}/add`,{...payload})
        return response.data
    }catch(error){
        return error;
    }
}

export const allAccessGet = async() => {
    try{
        const response = await api.get(`${BaseURL.API_ALL_ACCESS}`)
        return response.data
    }catch(error){
        return error;
    }
}
export const allAccessToggleStatus = async(payload) => {
    try{
        const response = await api.patch(`${BaseURL.API_ALL_ACCESS}/toggle-status/${payload._id}`,{status:payload.status})
        return response.data
    }catch(error){
        return error;
    }
}

