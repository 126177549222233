import api from './api.interface';
import BaseURL from '_Constants/url';

export const newAdminUser = async (body) => {
    try {
        const response = await api.post(BaseURL.API_ADMIN_USER, body);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAdminUsers = async () => {
    try {
        const response = await api.get(BaseURL.API_ADMIN_USER);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateAdminUser = async (body) => {
    try {
        const response = await api.put(BaseURL.API_ADMIN_USER, body);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const resetAdminUserPswd = async (_id, data = false) => {
    try {
        if (!data) {
            const response = await api.get(
                `${BaseURL.API_ADMIN_USER}/reset-pswd?_id=${_id}`
            );
            return response.data;
        } else {
            const response = await api.post(
                `${BaseURL.API_ADMIN_USER}/reset-pswd?_id=${_id}`,
                data
            );
            return response.data;
        }
    } catch (error) {
        return error;
    }
};

export const deleteAdminUser = async ({ email, adminId }) => {
    try {
        const response = await api.delete(
            `${BaseURL.API_ADMIN_USER}?email=${email}&&deletedBy=${adminId}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
