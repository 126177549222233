import BaseURL from '../constants/url';
import api from './api.interface';

export const getBanner = async () => {
    try {
        const response = await api.get(BaseURL.API_BANNER);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const addBanner = async (payload) => {
    try {
        const response = await api.post(`${BaseURL.API_BANNER}/add`, payload);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateBanner = async (payload) => {
    try {
        const response = await api.put(`${BaseURL.API_BANNER}/update`, payload);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateBannersPosition = async (payload) => {
    try {
        const response = await api.put(
            `${BaseURL.API_BANNER}/position`,
            payload
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const deleteBanner = async (bannerId) => {
    try {
        const response = await api.delete(
            `${BaseURL.API_BANNER}/${bannerId}/delete`
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
