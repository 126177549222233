import api from './api.interface';
import BaseURL from '_Constants/url';

export const getCourses = async () => {
    try {
        const response = await api.get(BaseURL.API_COURSE);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addNewCourse = async (payload) => {
    try {
        const response = await api.post(BaseURL.API_COURSE, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateCourse = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_COURSE, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateCourseStatus = async (payload) => {
    try {
        const response = await api.put(`${BaseURL.API_COURSE}/status`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateCoursePositions = async (payload) => {
    try {
        const response = await api.put(
            `${BaseURL.API_COURSE}/position`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getFeatCourses = async () => {
    try {
        const response = await api.get(`${BaseURL.API_COURSE}/featured`);
        return response.data;
    } catch (error) {
        return error
    }
}

export const updateCourseFeatureStatus = async (payload) => {
    try {
        const response = await api.put(`${BaseURL.API_COURSE}/featured`,payload);
        return response.data;
    } catch (error) {
        return error
    }
}