import api from './api.interface';
import BaseURL from '_Constants/url';

export const loginUser = async ({ email, password }) => {
    try {
        const response = await api.post(`${BaseURL.API_AUTH}/login`, {
            email,
            password,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserInfo = async (token) => {
    try {
        const response = await api.get(
            `${BaseURL.API_AUTH}/user-info?token=${token}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getZoomJWT = async ({ mentorId, eventTime }) => {
    try {
        const response = await api.get(
            `${BaseURL.API_AUTH}/zoom?mentorId=${mentorId}&eventTime=${eventTime}`
        );
        return response.data.success ? response.data.data : '';
    } catch (error) {
        return error;
    }
};
