import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import rootStore from '_Store';
import TypographyComponent from '_FormComps/TypographyComponent';
import { RHFTextField } from '_FormComps/TextField';
import { RHFSubmitBtn, RHFCancelBtn } from '_FormComps/Button';
import { resetAdminUserPswd } from '_Services/admin.service';
import { resetMentorPswd } from '_Services/mentor.service';

const ResetPassForm = () => {
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    let res;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const submitForm = async (data) => {
        if (data.newPassword !== data.confirmPassword) {
            alert('New passwords do not match');
            return;
        }

        if (data.oldPassword === data.newPassword) {
            rootStore.toast.showToast({
                method: 'error',
                message: 'New Password cannot be same as old password.',
            });
            return;
        }

        if (!rootStore.auth.isMentor)
            res = await resetAdminUserPswd(rootStore.auth.adminId, data);
        else res = await resetMentorPswd(rootStore.auth.adminId, data);

        if (res.success) {
            rootStore.toast.showToast({
                method: 'success',
                message: res.message,
            });
            rootStore.auth.logoutUserAction();
            setRedirect(true);
        } else {
            rootStore.toast.showToast({
                method: 'error',
                message: res.message,
            });
        }
    };

    const handleCancel = () => setRedirect(true);

    if (redirect) {
        history.goBack();
    }

    return (
        <div className="form-div">
            <Grid>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item container xs={12}>
                            <Typography variant="h5" gutterBottom>
                                Reset Password
                            </Typography>
                        </Grid>

                        <Grid item container xs={12}>
                            <Grid item xs={3}>
                                <TypographyComponent text="Old Password" />
                            </Grid>
                            <Grid item xs={9}>
                                <RHFTextField
                                    autoFocus={true}
                                    placeholder="Old Password"
                                    {...register('oldPassword', {
                                        required: {
                                            value: true,
                                            message: 'Please fill this field',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Please enter atleast 10 characters',
                                        },
                                    })}
                                    error={errors?.oldPassword ? true : false}
                                    helperText={errors?.oldPassword?.message}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12}>
                            <Grid item xs={3}>
                                <TypographyComponent text="New Password" />
                            </Grid>
                            <Grid item xs={9}>
                                <RHFTextField
                                    placeholder="New Password"
                                    {...register('newPassword', {
                                        required: {
                                            value: true,
                                            message: 'Please fill this field',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Please enter atleast 10 characters',
                                        },
                                    })}
                                    error={errors?.newPassword ? true : false}
                                    helperText={errors?.newPassword?.message}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12}>
                            <Grid item xs={3}>
                                <TypographyComponent text="Confirm Password" />
                            </Grid>
                            <Grid item xs={9}>
                                <RHFTextField
                                    placeholder="Confirm Password"
                                    {...register('confirmPassword', {
                                        required: {
                                            value: true,
                                            message: 'Please fill this field',
                                        },
                                        minLength: {
                                            value: 10,
                                            message:
                                                'Please enter atleast 10 characters',
                                        },
                                    })}
                                    error={
                                        errors?.confirmPassword ? true : false
                                    }
                                    helperText={
                                        errors?.confirmPassword?.message
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            justifyContent="center"
                            alignItems="baseline"
                            style={{
                                marginBottom: '30px',
                                marginTop: '20px',
                            }}
                        >
                            <Grid item sm={3}>
                                <Grid container justifyContent="center">
                                    <RHFSubmitBtn />
                                </Grid>
                            </Grid>
                            <Grid item sm={3}>
                                <Grid container justifyContent="flex-start">
                                    <RHFCancelBtn onClick={handleCancel} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </div>
    );
};

export default observer(ResetPassForm);
