import { createTheme } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

export default createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: lightBlue.A200,
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {},
        },
        MuiStepIcon: {
            text: {
                color: lightBlue.A700,
            },
        },
        MuiPickersDay: {
            day: {
                color: lightBlue.A700,
            },
            current: {
                color: lightBlue['900'],
            },
        },
        MuiPickersModal: {},
        MuiInput: {
            underline: {
                borderBottom: '1px solid black',
                '&:before': {
                    borderBottom: '1px solid rgba(255, 255, 255, 255)',
                },
                '&:after': {
                    borderBottom: '1px solid rgba(255, 255, 255, 255)',
                },
            },
        },
        MuiInputLabel: {
            root: {
                color: '#afafaf',
                fontSize: '16px',
                '&$focused': {
                    fontSize: '12px',
                    fontWeight: 'normal',
                },
                '&$disabled': {
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: 'normal',
                },
                '&$error': {
                    color: '#fff',
                    fontSize: '12px',
                    fontWeight: 'normal',
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '12px',
            },
        },
        MuiRadio: {
            root: {
                color: '#000000',
            },
        },
        MuiButton: {
            root: {
                color: 'grey',
            },
            outlined: {
                '&$disabled': {
                    border: '1px solid #374f21 !important',
                    color: '#374f21 !important',
                },
            },
            text: {
                '&$disabled': {
                    color: '#374f21 !important',
                    fontSize: '14px',
                },
            },
        },
        MuiAppBar: {
            root: {
                boxShadow: 'none',
            },
        },
    },
    palette: {
        // used for bg-color like button, appbar text
        primary: {
            main: '#0080ff',
            contrastText: '#fff',
        },
        secondary: {
            main: '#0080ff',
            contrastText: '#fff',
        },
        text: {
            primary: '#000000',
            secondary: '#999',
        },
        // background color of whole body
        background: {},
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Brandon Grotesque',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});
