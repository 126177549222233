import { makeAutoObservable } from 'mobx';
import {
    getRevenueReleaseData,
    addRevenueReleaseData,
} from '_Services/revenue-release.service';

class RevenueStore {
    revenueReleaseData = [];
    revenueUpdate = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getRevenueReleaseAction(mentorId) {
        try {
            const response = await getRevenueReleaseData(mentorId);
            if (response.success) {
                this.revenueReleaseData = response.data;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async addRevenueAction(payload) {
        try {
            const response = await addRevenueReleaseData(payload);
            if (response.success) {
                this.revenueUpdate = response;
            }
        } catch (error) {
            return;
        }
    }
}

export default RevenueStore;
