import { Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import rootStore from '_Store';

/* Routes accessible to both admin & mentor */
export const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn !== false ? <Component {...props} /> : null
        }
    />
);

/* Routes accessible to only admin */
export const AdminRoute = observer(
    ({ component: Component, isLoggedIn, ...rest }) => (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn !== false && rootStore.auth.isAdmin ? (
                    <Component {...props} />
                ) : null
            }
        />
    )
);

/* Routes accessible to only admin or support */
export const SupportRoute = observer(
    ({ component: Component, isLoggedIn, ...rest }) => (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn !== false && !rootStore.auth.isMentor ? (
                    <Component {...props} />
                ) : null
            }
        />
    )
);

/* Routes accessible to only mentor */
export const MentorRoute = observer(
    ({ component: Component, isLoggedIn, ...rest }) => (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn !== false && rootStore.auth.isMentor ? (
                    <Component {...props} />
                ) : null
            }
        />
    )
);

/* Routes accessible to only admin and sales */
export const SalesRoute = observer(
    ({ component: Component, isLoggedIn, ...rest }) => (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn !== false &&
                (rootStore.auth.isAdmin || rootStore.auth.isSales) ? (
                    <Component {...props} />
                ) : null
            }
        />
    )
);
