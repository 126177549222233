import api from './api.interface';
import BaseURL from '_Constants/url';

export const getConstants = async () => {
    try {
        const response = await api.get(BaseURL.API_CONSTANTS);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
