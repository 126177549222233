import api from './api.interface';
import BaseURL from '_Constants/url';

export const getMentors = async () => {
    try {
        const response = await api.get(BaseURL.API_MENTOR);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addMentor = async (payload) => {
    try {
        const response = await api.post(BaseURL.API_MENTOR, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateMentor = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_MENTOR, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const resetMentorPswd = async (_id, data = false) => {
    try {
        if (!data) {
            const response = await api.get(
                `${BaseURL.API_MENTOR}/reset-pswd?_id=${_id}`
            );
            return response.data;
        } else {
            const response = await api.post(
                `${BaseURL.API_MENTOR}/reset-pswd?_id=${_id}`,
                data
            );
            return response.data;
        }
    } catch (error) {
        return error;
    }
};

export const getMentorTimeslots = async (mentorId) => {
    try {
        const response = await api.get(
            `${BaseURL.API_TIMESLOT}?mentorId=${mentorId}`
        );
        return response.data.data;
    } catch (error) {
        return error;
    }
};

export const addTimeslot = async (payload) => {
    try {
        const response = await api.post(`${BaseURL.API_TIMESLOT}/add`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteTimeslot = async (payload) => {
    try {
        const response = await api.delete(
            `${BaseURL.API_TIMESLOT}?_id=${payload}`
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
