import React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import rootStore from '_Store';

const PREFIX = 'Toast';

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
));

const Toast = observer(() => {
    const closeHandler = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        rootStore.toast.closeToast();
    };

    return (
        <Root className={classes.root}>
            {rootStore.toast.toastMessage && (
                <Snackbar
                    open={rootStore.toast.toastState}
                    autoHideDuration={3000}
                    onClose={closeHandler}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <Alert
                        onClose={closeHandler}
                        severity={rootStore.toast.toastMethod}
                    >
                        {rootStore.toast.toastMessage}
                    </Alert>
                </Snackbar>
            )}
        </Root>
    );
});

export default Toast;
