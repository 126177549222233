import api from './api.interface';
import BaseURL from '_Constants/url';

export const getNotifications = async () => {
    try {
        const response = await api.get(BaseURL.API_NOTIFICATION);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addNotification = async (payload) => {
    try {
        const response = await api.post(BaseURL.API_NOTIFICATION, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateNotification = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_NOTIFICATION, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};
