import { makeAutoObservable } from 'mobx';
import { getAnalysis, updateAnalysis } from '_Services/analysis.service';
import { getDACAnalytics } from '../services/analysis.service';

class AnalysisStore {
    aiAnalysis = {
        _id: '',
        title: '',
        description: '',
        prices: [
            { amount: '', currency: '', country: '', symbol: '', striked: '' },
        ],
        active: false,
    };
    dialCoach = {
        title: '',
        description: '',
    };
    updateAnalysis = {};
    dialCoachAnalytics = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async updateAIAnalysisAction(payload) {
        try {
            const response = await updateAnalysis(payload);
            if (response.success) {
                this.updateAnalysis = response;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getAIAnalysisAction(kind) {
        try {
            const response = await getAnalysis(kind);
            if (response.success && response.data) {
                if (response.data.type === 'AI') {
                    this.aiAnalysis = response.data;
                } else {
                    this.dialCoach = response.data;
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getDialACoachAnalytics(query) {
        try {
            const response = await getDACAnalytics(query);
            if (response.success) {
                this.dialCoachAnalytics = response.data;
            }
        } catch (error) {
            console.error(error);
        }
    }

    resetUpdateAnalysis() {
        this.updateAnalysis = {};
    }
}

export default AnalysisStore;
