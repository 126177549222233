import { makeAutoObservable } from 'mobx';
import {
    addMentor,
    addTimeslot,
    getMentors,
    updateMentor,
    deleteTimeslot,
} from '_Services/mentor.service';

class MentorStore {
    mentors = [];
    mentorUpdate = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getMentorsAction() {
        try {
            const response = await getMentors();
            if (response.success) {
                this.mentors = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async addMentorAction(payload) {
        try {
            const response = await addMentor(payload);
            if (response.success) {
                this.mentorUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateMentorAction(payload) {
        try {
            const response = await updateMentor(payload);
            if (response.success) {
                this.mentorUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async addMentorTimeSlots(payload) {
        try {
            const response = await addTimeslot(payload);
            if (response.success) {
                this.mentorUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async deleteTimeSlot(payload) {
        try {
            const response = await deleteTimeslot(payload);
            if (response.success) {
                this.mentorUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    getMentorById(mentorId) {
        const filterMentor = this.mentors.filter(
            (mentor) => mentor._id === mentorId
        );
        return filterMentor[0];
    }

    resetUpdateMentor() {
        this.mentorUpdate = {};
    }
}

export default MentorStore;
