/* List of BaseURLs of all APIs defined in "admin client" pkg */
const BaseURL = {
    API_ADMIN_USER: `/admin-user`,
    API_ALL_ACCESS:`/all-access`,
    API_ANALYSIS: `/analysis`,
    API_ANALYTICS: `/analytics`,
    API_AUTH: `/auth`,
    API_BANNER: '/banner',
    API_COUPON: `/coupon`,
    API_COURSE: `/course`,
    API_CONSTANTS: '/constants',
    API_FILE: `/file`,
    API_INTRO: `/intro`,
    API_MENTOR: `/mentor`,
    API_NOTIFICATION: `/notification`,
    API_PACKAGE: `/package`,
    API_PASS: `/pass`,
    API_PRICE: `/price`,
    API_RATIO: `/ratio`,
    API_REVENUE_RELEASE: '/revenue',
    API_SUPPORT: `/support`,
    API_TIMESLOT: `/timeslot`,
    API_USER: `/user`,
    API_WORDS: `/words`,
};

export default BaseURL;
