import api from './api.interface';
import BaseURL from '_Constants/url';

export const getAnalysis = async (type = 'MENTOR') => {
    try {
        const response = await api.get(`${BaseURL.API_ANALYSIS}/${type}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateAnalysis = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_ANALYSIS, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getDACAnalytics = async (payload) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/notify-dac`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
