import { makeAutoObservable } from 'mobx';
import {
    getCourseById,
    addLesson,
    updateLesson,
} from '_Services/lesson.service';

class LessonStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    lessonCourse = {};
    updateLesson = {};

    async getLessonCourse({ courseId }) {
        try {
            const response = await getCourseById({ courseId });
            if (response.success) {
                /* Sorting Lessongs by Index */
                const lessonArr = response.data.lessons.sort(
                    (a, b) => a?.index - b?.index
                );
                this.lessonCourse = lessonArr;
            }
        } catch (err) {
            return;
        }
    }

    async addLessonAction(payload) {
        try {
            const response = await addLesson(payload);
            if (response.success) {
                this.updateLesson = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateLessonAction(payload) {
        try {
            const response = await updateLesson(payload);
            if (response.success) {
                this.updateLesson = response;
            }
        } catch (err) {
            return;
        }
    }

    async getLessonById({ lessonId, courseId }) {
        if (this.lessonCourse.data && this.lessonCourse.data.lessons) {
            const filterCourse = this.lessonCourse.data.lessons.filter(
                (lesson) => lesson._id === lessonId
            );
            this.updateLesson = filterCourse[0];
        } else {
            await this.getLessonCourse({ courseId });
            this.getLessonById({ lessonId, courseId });
        }
    }

    resetupdateLesson() {
        this.updateLesson = {};
    }
}

export default LessonStore;
