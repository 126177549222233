import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OverviewIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IndexRoute from '_Routes/index.routes';
import { sideBarOptions, OverviewList } from '_Constants/sidebarOptions';
import rootStore from '_Store';
import Can from '_Utils/Can';
import { useLocation } from 'react-router';

const drawerWidth = 265;

const PREFIX = 'Landing';

const classes = {
    authButton: `${PREFIX}-authButton`,
    authLink: `${PREFIX}-authLink`,
    buttonColor: `${PREFIX}-buttonColor`,
    iconColor: `${PREFIX}-iconColor`,
};

const Container = styled('div')(({ theme }) => ({
    [`& .${classes.authButton}`]: {
        textDecoration: 'none',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    [`& .${classes.authLink}`]: {
        display: 'flex',
        justifyContent: 'center',
    },

    [`& .${classes.buttonColor}`]: { color: '#fff' },
    [`& .${classes.iconColor}`]: { color: 'blue' },
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

function Landing() {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [overviewExpand, setoverviewExpand] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const location = useLocation();

    useEffect(() => {
        setSelectedOption(location.pathname);
    }, [location.pathname]);

    let name = '';

    const iconWidth = rootStore.auth.isNavOpen ? 36 : 56;

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const logoutHandler = () => rootStore.auth.logoutUserAction();
    const handleDrawerOpen = () => rootStore.auth.navbarToggleAction(true);
    const handleDrawerClose = () => rootStore.auth.navbarToggleAction(false);
    const handleToggle = (type) => {
        if (type === 'overview') {
            setoverviewExpand(!overviewExpand);
        }
    };
    const authLink = (
        <Grid item className={classes.authLink}>
            <Button
                type="submit"
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size="medium"
                style={{
                    color: 'inherit',
                }}
            >
                <Typography
                    style={{
                        color: 'inherit',
                        paddingRight: '5px',
                    }}
                >
                    {name}
                </Typography>
                <AccountCircleIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    Hi, {rootStore.auth.userName}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/user/${rootStore.auth.adminId}/reset-pass`}
                        className="link"
                    >
                        Change Password
                    </Link>
                </MenuItem>

                <MenuItem
                    onClick={handleClose && logoutHandler}
                    className={`${classes.authLink}`}
                    style={{ color: 'red' }}
                >
                    Logout
                </MenuItem>
            </Menu>
        </Grid>
    );
    return (
        <Container>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={rootStore.auth.isNavOpen}>
                    <Toolbar>
                        {rootStore.auth.loggedIn && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginRight: '36px',
                                    ...(rootStore.auth.isNavOpen && {
                                        display: 'none',
                                    }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link
                                    className={`link ${classes.buttonColor}`}
                                    to="/dashboard"
                                >
                                    <Typography variant="h6">
                                        Cricuru Dashboard
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item className={classes.authLink}>
                                {rootStore.auth.loggedIn && authLink}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {rootStore.auth.loggedIn && (
                    <>
                        <Drawer
                            variant="permanent"
                            open={rootStore.auth.isNavOpen}
                        >
                            <DrawerHeader>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? (
                                        <ChevronRightIcon />
                                    ) : (
                                        <ChevronLeftIcon />
                                    )}
                                </IconButton>
                            </DrawerHeader>
                            <Divider />
                            <List>
                                <Can I="view" a="Overview">
                                    <ListItem
                                        button
                                        onClick={() => handleToggle('overview')}
                                    >
                                        <Tooltip
                                            title="Overview"
                                            placement="right"
                                        >
                                            <ListItemIcon
                                                onClick={handleDrawerOpen}
                                                sx={{
                                                    minWidth: iconWidth,
                                                }}
                                            >
                                                <OverviewIcon />
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText>
                                            <Typography>Overview</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            {overviewExpand ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )}
                                        </ListItemIcon>
                                        <Divider />
                                    </ListItem>
                                </Can>
                                {overviewExpand ? (
                                    <>
                                        <Divider />
                                        <List>
                                            {OverviewList.map((item, _i) => (
                                                <Link
                                                    key={_i}
                                                    to={item.url}
                                                    className="navlink"
                                                >
                                                    <ListItem
                                                        button
                                                        selected={
                                                            item.url ===
                                                            selectedOption
                                                        }
                                                    >
                                                        <Tooltip
                                                            title={item.text}
                                                            placement="right"
                                                        >
                                                            <ListItemIcon
                                                                className={
                                                                    item.url ===
                                                                        selectedOption &&
                                                                    classes.iconColor
                                                                }
                                                                sx={{
                                                                    minWidth:
                                                                        iconWidth,
                                                                }}
                                                            >
                                                                {<item.icon />}
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                        <ListItemText>
                                                            {item.text}
                                                        </ListItemText>
                                                    </ListItem>
                                                </Link>
                                            ))}
                                        </List>
                                        <Divider />
                                    </>
                                ) : (
                                    <Divider />
                                )}
                                {sideBarOptions.map((option) => (
                                    <Can
                                        I="view"
                                        a={option.a}
                                        key={option.linkTo}
                                    >
                                        <Link
                                            to={option.linkTo}
                                            className="navlink"
                                        >
                                            <ListItem
                                                button
                                                selected={
                                                    option.linkTo ===
                                                    selectedOption
                                                }
                                            >
                                                <Tooltip
                                                    title={option.text}
                                                    placement="right"
                                                >
                                                    <ListItemIcon
                                                        className={
                                                            option.linkTo ===
                                                                selectedOption &&
                                                            classes.iconColor
                                                        }
                                                        sx={{
                                                            minWidth: iconWidth,
                                                        }}
                                                    >
                                                        <option.icon />
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText>
                                                    <Typography>
                                                        {option.text}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </Link>
                                        <Divider />
                                    </Can>
                                ))}
                            </List>
                            <Divider />
                        </Drawer>
                    </>
                )}
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3 }}
                    style={{ marginTop: '50px' }}
                >
                    <IndexRoute />
                </Box>
            </Box>
        </Container>
    );
}

export default observer(Landing);
