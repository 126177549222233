import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import rootStore from '_Store';

function RedirectHandler() {
    return (
        <div>
            {rootStore.toast.redirectState ? (
                <Redirect to={rootStore.toast.redirectLocation} />
            ) : null}
        </div>
    );
}

export default observer(RedirectHandler);
