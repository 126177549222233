import api from './api.interface';
import BaseURL from '_Constants/url';

/* ---------- Overall Sales ---------- */
export const getOverallSales = async ({ month, year }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/overall-sales`,
            { month, year }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* Overall sales for logged in mentor */
export const getOverallSalesforMentor = async ({ _id, month, year }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/mentor-overall-sales`,
            { _id, month, year }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// This is needed for revenue module and it is independent of time period
export const getAllTimeSalesforMentor = async (id) => {
    try {
        const response = await api.get(
            `${BaseURL.API_ANALYTICS}/mentor-alltime-sales/${id}`
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

/* ---------- AI ---------- */
export const getAIAnalytics = async (startDate, endDate) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/ai-analysis`,
            {
                startDate,
                endDate,
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* Get Sales group by days & month for a given year */
export const getAISales = async ({ _id, month, year }) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/ai-sales`, {
            _id,
            month,
            year,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ---------- Mentor ---------- */
export const getMentorAnalytics = async (queryObj) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/mentor`,
            queryObj
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* Get Sales group by days & month for a given year */
export const getMentorSales = async ({ _id, month, year }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/mentor-sales`,
            { _id, month, year }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ---------- Coupon ---------- */
export const getCouponAnalytics = async ({ month, year }) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/coupon`, {
            month,
            year,
        });
        return response.data?.data;
    } catch (error) {
        return error;
    }
};

export const getCouponRedeems = async ({ _id, month, year }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/coupon-redeems`,
            {
                _id,
                month,
                year,
            }
        );
        return response.data?.data;
    } catch (error) {
        return error;
    }
};

/* ---------- Course ---------- */
export const getCourseAnalytics = async (queryObj) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/course`,
            queryObj
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getCourseSales = async ({ _id, month, year }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/course-sales`,
            { _id, month, year }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ---------- Package ---------- */
export const getPackageAnalytics = async (startDate, endDate) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/package`, {
            startDate,
            endDate,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getPackageSales = async ({ _id, month, year }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/package-sales`,
            { _id, month, year }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ---------- Pass ---------- */
export const getPassAnalytics = async (startDate, endDate) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/pass`, {
            startDate,
            endDate,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getPassSales = async ({ _id, month, year }) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/pass-sales`, {
            _id,
            month,
            year,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getPassRedeems = async ({ startDate, endDate }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/pass-redeems`,
            {
                startDate,
                endDate,
            }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ---------- User ---------- */
export const getUserActivity = async ({ startDate, endDate }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_ANALYTICS}/users-activity`,
            { startDate, endDate }
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUsersInfo = async ({ startDate, endDate }) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/users-info`, {
            startDate,
            endDate,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

/* ---------- Invoice ---------- */
export const getInvoices = async ({ startDate, endDate }) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/invoices`, {
            startDate,
            endDate,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};


/* ---------- All Access ---------- */

export const getAllAccessAnalytics = async (startDate,endDate) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/all-access`,{startDate,endDate})
        return response.data
    } catch (error) {
        return error;
    }
}

export const getAllAccessSales = async ({ _id, month, year }) => {
    try {
        const response = await api.post(`${BaseURL.API_ANALYTICS}/all-access-sales`,{_id,month, year})
        return response.data
    } catch (error) {
        return error;
    }
}
