import { makeAutoObservable } from 'mobx';

class toastStore {
    toastState = false;
    toastMessage = '';
    toastMethod = 'success';
    loaderState = false;
    redirectState = false;
    redirectLocation = '';

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    enableLoader() {
        this.loaderState = true;
    }

    disableLoader() {
        this.loaderState = false;
    }

    showToast({ method, message }) {
        this.toastMessage = message;
        this.toastMethod = method;
        this.toastState = true;
    }

    closeToast() {
        this.toastState = false;
    }

    enableRedirect(location) {
        this.redirectLocation = location;
        this.redirectState = true;
        setTimeout(this.disableRedirect, 100);
    }

    disableRedirect() {
        this.redirectState = false;
    }
}

export default toastStore;
