import { makeAutoObservable } from 'mobx';
import {allAccessUpdate,allAccessAdd,allAccessGet,allAccessToggleStatus} from '_Services/allAccess.service'

class AllAccessStore {
    allAccessPass = []
    
    updateAllAccess = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async updateAllAccessAction(payload) {
        try {
            const response = await allAccessUpdate(payload);
            if (response.success) {
                this.updateAllAccess = response;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async addAllAccessAction(payload) {
        try{
            const response = await allAccessAdd(payload)
            if(response.success){
                this.updateAllAccess = response;
            }
        }catch(err){
            console.log(err)
        }
    }

    async getAllAccessAction() {
        try{
            const response = await allAccessGet()
            if(response.success){
                this.allAccessPass = response.data;
            }
        }catch(err){
            console.log(err)
        }
    }

     async toggleAllAccessStatusAction(payload) {
        try{
            const response = await allAccessToggleStatus(payload)
            if(response.success){
                this.allAccessPass = response.data;
            }
        }catch(err){
            console.log(err)
        }
    }

    resetUpdateAllAccess() {
        this.updateAllAccess = {};
    }
}

export default AllAccessStore;
