import { makeAutoObservable } from 'mobx';
import {
    getCoupons,
    addCoupon,
    updateCoupon,
    getAnalytics,
    bulkUpdateCoupons,
    getCouponsByBatchId,
} from '_Services/coupon.service';

class CouponStore {
    numPages = 1;
    couponBatchList = [];
    couponsList = [];
    couponUpdate = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getCoupons({ pageNum = 1, numItems = 20 }) {
        try {
            const response = await getCoupons({ pageNum, numItems });
            if (response.success) {
                this.numPages = response.data.numPages;
                this.couponBatchList = response.data.list;
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async getCouponsByBatchAction({ batch, pageNum = 1, numItems = 20 }) {
        try {
            const response = await getCouponsByBatchId({
                batch,
                pageNum,
                numItems,
            });
            if (response.success) {
                this.couponsList = response.data;
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async addCouponAction(payload) {
        try {
            const response = await addCoupon(payload);
            if (response.success) {
                this.couponUpdate = response;
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async updateCouponAction(payload) {
        try {
            const response = await updateCoupon(payload);
            if (response.success) {
                this.couponUpdate = response;
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async bulkUpdateCouponAction(payload) {
        try {
            const response = await bulkUpdateCoupons(payload);
            if (response.success) {
                this.couponUpdate = response;
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    async getAnalyticsAction(payload) {
        try {
            const response = await getAnalytics(payload);
            if (response.success) {
                return response.data;
            }
        } catch (err) {
            console.error(err);
            return;
        }
    }

    resetCouponUpdate() {
        this.couponUpdate = {};
    }
}

export default CouponStore;
