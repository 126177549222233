import { makeAutoObservable } from 'mobx';
import { getIntro, updateIntro } from '_Services/intro.service';

class IntroStore {
    overviewIntro = {
        title: '',
        description: '',
        videoURL: '',
        thumbnailURL: '',
        type: 'HOME',
    };

    analysisIntro = {
        title: '',
        description: '',
        videoURL: '',
        thumbnailURL: '',
        type: 'ANALYSIS',
    };

    updateIntro = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getOverviewIntro(type) {
        try {
            const response = await getIntro(type);
            if (type === 'HOME') {
                this.overviewIntro = Object.assign(
                    this.overviewIntro,
                    response.data
                );
            } else if (type === 'ANALYSIS') {
                this.analysisIntro = Object.assign(
                    this.overviewIntro,
                    response.data
                );
            }
        } catch (err) {
            console.error(err);
        }
    }

    async updateOverviewIntro(payload) {
        try {
            const response = await updateIntro(payload);
            this.updateIntro = response;
        } catch (err) {
            return;
        }
    }

    updateIntroReset() {
        this.updateIntro = {};
    }
}

export default IntroStore;
