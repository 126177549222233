import { makeAutoObservable } from 'mobx';
import {
    addNotification,
    getNotifications,
    updateNotification,
} from '_Services/notification.service';

class NotificationStore {
    notifications = [];
    notificationUpdate = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getNotificationsAction() {
        try {
            const response = await getNotifications();
            if (response.success) {
                this.notifications = response.data;
            }
        } catch (err) {
            return;
        }
    }

    async addNotificationAction(payload) {
        try {
            const response = await addNotification(payload);
            if (response.success) {
                this.notificationUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateNotificationAction(payload) {
        try {
            const response = await updateNotification(payload);
            if (response.success) {
                this.notificationUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    resetUpdateNotification() {
        this.notificationUpdate = {};
    }
}

export default NotificationStore;
