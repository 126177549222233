import { makeAutoObservable } from 'mobx';
import {
    getCourses,
    addNewCourse,
    updateCourse,
    updateCourseStatus,
    updateCoursePositions,
    updateCourseFeatureStatus
} from '_Services/course.service';

class CourseStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    courses = [];
    featured = [];
    newlyAdded = [];
    courseUpdate = {};

    async getAllCourses() {
        try {
            const response = await getCourses();
            if (response.success) {
                this.courses = response.data;
            }
        } catch (err) {
            return;
        }
    }

    async addNewCourse(payload) {
        try {
            const response = await addNewCourse(payload);
            if (response.success) {
                this.courseUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateCourse(payload) {
        try {
            const response = await updateCourse(payload);
            if (response.success) {
                this.courseUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateCourseStatus(payload) {
        try {
            const response = await updateCourseStatus(payload);
            if (response.success) {
                this.courseUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    async updateCoursePositions(payload) {
        try {
            const response = await updateCoursePositions(payload);
            if (response.success) {
                this.courseUpdate = response;
                this.getAllCourses();
            }
        } catch (err) {
            return;
        }
    }

    async updateCourseFeatureStatus(payload) {
        try {
            const response = await updateCourseFeatureStatus(payload);
            if (response.success) {
                this.courseUpdate = response;
            }
        } catch (err) {
            return;
        }
    }

    getCourseById(courseId) {
        const filterCourse = this.courses.filter(
            (course) => course._id === courseId
        );
        return filterCourse[0];
    }

    resetCourseUpdate() {
        this.courseUpdate = {};
    }
}

export default CourseStore;
