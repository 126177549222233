import { useEffect } from 'react';
import Landing from '_Shared/Landing';
import Toast from '_Shared/Toast';
import Loader from '_Shared/Loader';
import RedirectHandler from '_Shared/RedirectHandler';
import rootStore from '_Store';

const App = () => {
    useEffect(() => {
        document.addEventListener('wheel', function () {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        });

        const token = localStorage.getItem('auth');
        if (token !== null) {
            rootStore.auth.loggedIn = true;
            rootStore.auth.getUserInfoAction(token);
            rootStore.constants.getConstantsAction();
        } else {
            rootStore.toast.enableRedirect('/login');
        }
    }, []);

    return (
        <div>
            <Landing></Landing>
            <Toast />
            <Loader />
            <RedirectHandler />
        </div>
    );
};

export default App;
