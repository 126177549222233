import { makeAutoObservable } from 'mobx';
import {
    newAdminUser,
    updateAdminUser,
    getAdminUsers,
} from '_Services/admin.service';

class AdminUserStore {
    adminUserArr = [];
    updateAdminUser = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async newAdminUserAction(payload) {
        try {
            const response = await newAdminUser(payload);
            if (response.success) {
                this.updateAdminUser = response;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async updateAdminUsersAction(payload) {
        try {
            const response = await updateAdminUser(payload);
            if (response.success) {
                this.updateAdminUser = response;
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getAdminUsersAction() {
        try {
            const response = await getAdminUsers();
            if (response.success && response.data) {
                this.adminUserArr = response.data;
            }
        } catch (err) {
            console.error(err);
        }
    }

    resetUpdateAdminUser() {
        this.updateAdminUser = {};
    }
}

export default AdminUserStore;
