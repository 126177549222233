import { makeAutoObservable } from 'mobx';
import { loginUser, getUserInfo } from '_Services/auth.service';

class authStore {
    loggedIn = false;
    userName = '';
    adminId = '';
    isAdmin = false;
    isMentor = false;
    isSupport = false;
    isSales = false;
    isNavOpen = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loginUserAction({ email, password }) {
        try {
            const response = await loginUser({ email, password });
            if (response.success) {
                this.loggedIn = true;
                this.isNavOpen = true;

                localStorage.setItem('auth', response.data.auth.token);

                /* Get logged in admin _id, role */
                await this.getUserInfoAction(response.data.auth.token);

                await this.rootStore.constants.getConstantsAction();

                /* Redirects for Admin, Mentor & Support Respectively */
                if (this.isAdmin) {
                    this.rootStore.toast.enableRedirect('/dashboard');
                }
                if (this.isMentor) {
                    this.rootStore.toast.enableRedirect('/schedule');
                }
                if (this.isSupport) {
                    this.rootStore.toast.enableRedirect('/search-user');
                }
                if (this.isSales) {
                    this.rootStore.toast.enableRedirect('/analytics');
                } else if (!this.isAdmin && !this.isSupport && !this.isMentor) {
                    this.logoutUserAction();
                }
            }
        } catch (err) {
            this.loggedIn = false;
            console.error(err);
        }
    }

    async getUserInfoAction(token) {
        try {
            const response = await getUserInfo(token);

            if (response.success) {
                this.userName = response.data.name;
                this.adminId = response.data._id;
                response.data.isAdmin && (this.isAdmin = true);
                response.data.isMentor && (this.isMentor = true);
                response.data.isSupport && (this.isSupport = true);
                response.data.isSales && (this.isSales = true);
            }
        } catch (err) {
            console.error(err);
        }
    }

    logoutUserAction() {
        localStorage.clear();
        this.loggedIn = false;
        this.isAdmin = false;
        this.isMentor = false;
        this.isSupport = false;
        this.isNavOpen = false;

        window.location.replace('/login');
    }

    navbarToggleAction(navbarState) {
        this.isNavOpen = navbarState;
    }
}

export default authStore;
