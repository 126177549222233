import api from './api.interface';
import BaseURL from '_Constants/url';

export const getCourseById = async ({ courseId }) => {
    try {
        const response = await api.get(`${BaseURL.API_COURSE}/${courseId}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addLesson = async ({ lesson, courseId }) => {
    try {
        const response = await api.post(
            `${BaseURL.API_COURSE}/lesson/${courseId}`,
            lesson
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateLesson = async ({ lesson, lessonId }) => {
    try {
        const response = await api.put(
            `${BaseURL.API_COURSE}/lesson/${lessonId}`,
            lesson
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
