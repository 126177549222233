import { makeAutoObservable } from 'mobx';
import {
    addBanner,
    getBanner,
    updateBanner,
    updateBannersPosition,
    deleteBanner,
} from '_Services/banner.service';

export default class BannerStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }
    bannerList = [];
    bannerUpdate = {};

    async getBannersAction() {
        try {
            const res = await getBanner();
            if (res.success) {
                this.bannerList = res.data;
            }
        } catch (error) {
            console.error(error);
            return;
        }
    }

    async addBannerAction(payload) {
        try {
            const res = await addBanner(payload);
            if (res.success) {
                this.bannerUpdate = res;
            }
        } catch (error) {
            console.error(error);
            return;
        }
    }

    async updateBannerAction(payload) {
        try {
            const res = await updateBanner(payload);
            if (res.success) {
                this.bannerUpdate = res;
            }
        } catch (error) {
            console.error(error);
            return;
        }
    }

    async updateBannersPositionAction(payload) {
        try {
            const res = await updateBannersPosition(payload);
            if (res.success) {
                this.bannerUpdate = res;
            }
        } catch (error) {
            console.error(error);
            return;
        }
    }

    async deleteBannerAction() {
        try {
            const res = await deleteBanner();
            if (res.success) {
                this.bannerUpdate = res;
            }
        } catch (error) {
            console.error(error);
            return;
        }
    }

    resetBannerUpdate() {
        this.bannerUpdate = {};
    }
}
