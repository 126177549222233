import api from './api.interface';
import BaseURL from '_Constants/url';

export const getIntro = async (type = 'HOME') => {
    try {
        const response = await api.get(`${BaseURL.API_INTRO}/${type}`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateIntro = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_INTRO, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};
