import { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    PrivateRoute,
    AdminRoute,
    SupportRoute,
    MentorRoute,
    SalesRoute,
} from './PrivateRoutes';
import rootStore from '_Store';
import Login from '_Pages/auth/Login';
import Dashboard from '_Shared/Dashboard';
import Loader from '_Shared/Loader';
import ResetPassForm from '_Shared/ResetPassForm';

/* ---------- Admin User ---------- */
const AdminUserList = lazy(() => import('_Pages/admin-user/AdminUserList'));

const AddAdminUser = lazy(() => import('_Pages/admin-user/AddAdminUser'));

const UpdateAdminUser = lazy(() => import('_Pages/admin-user/UpdateAdminUser'));

/* ---------- All Access Pass ---------- */

const AllAccessPassList = lazy(() => import('_Pages/all-access/AllAccessList'));

const AddAllAccess = lazy(() => import('_Pages/all-access/AddAllAccess'));

const UpdateAllAccess = lazy(() => import('_Pages/all-access/AllAccessUpdate'));

const AllAccessAnalytics = lazy(() =>
    import('_Pages/analytics/sales/all-access/AllAccessAnalyticsData')
);

/* ---------- Analytics Overview ---------- */
const OverviewAnalytics = lazy(() => import('_Pages/analytics/overview'));

/* ---------- Analytics & Sales ---------- */
const SalesIndex = lazy(() => import('_Pages/analytics/sales'));

const AIAnalytics = lazy(() =>
    import('_Pages/analytics/sales/ai/AIAnalyticsData')
);

const CouponAnalytics = lazy(() =>
    import('_Pages/analytics/sales/coupon/CouponAnalytics')
);

const CouponRedeemsData = lazy(() =>
    import('_Pages/analytics/sales/coupon/CouponRedeemData')
);

const CourseAnalytics = lazy(() =>
    import('_Pages/analytics/sales/course/CourseAnalyticsData')
);

const MentorAnalytics = lazy(() =>
    import('_Pages/analytics/sales/mentor/MentorAnalyticsData')
);

const PackageAnalytics = lazy(() =>
    import('_Pages/analytics/sales/package/PackageAnalyticsData')
);

const PassAnalytics = lazy(() =>
    import('_Pages/analytics/sales/pass/PassAnalyticsData')
);

/* ---------- Banners ---------- */

const BannerList = lazy(() => import('_Pages/overview/banner/BannerList'));

const AddBanner = lazy(() => import('_Pages/overview/banner/AddBanner'));

const UpdateBanner = lazy(() => import('_Pages/overview/banner/UpdateBanner'));

/* ---------- Coupon ---------- */
const CouponBatchList = lazy(() =>
    import('_Pages/coupon/batch/CouponBatchList')
);

const CouponBatchUpdate = lazy(() =>
    import('_Pages/coupon/batch/CouponBatchUpdate')
);

const CouponList = lazy(() => import('_Pages/coupon/single/CouponList'));

const AddCoupon = lazy(() => import('_Pages/coupon/single/AddCoupon'));

/* ---------- Course ---------- */
const ViewCourses = lazy(() => import('_Pages/course/ViewCourses'));

const AddCourse = lazy(() => import('_Pages/course/AddCourse'));

const UpdateCourse = lazy(() => import('_Pages/course/UpdateCourse'));

/* ---------- Form Responses ---------- */
const FormResponses = lazy(() => import('_Pages/form-response/ResponseTabs'));

const ClickupForm = lazy(() => import('_Pages/form-response/ClickupForm'));

/* ---------- Lesson ---------- */
const LessonList = lazy(() => import('_Pages/lesson/LessonList'));

const AddLesson = lazy(() => import('_Pages/lesson/AddLesson'));

const UpdateLesson = lazy(() => import('_Pages/lesson/UpdateLesson'));

/* ---------- Mentor ---------- */
const MentorList = lazy(() => import('_Pages/mentor/MentorList'));

const AddMentor = lazy(() => import('_Pages/mentor/AddMentor'));

const UpdateMentor = lazy(() => import('_Pages/mentor/UpdateMentor'));

const MentorSchedule = lazy(() => import('_Pages/mentor/schedule/MeetingList'));

const MentorMeetings = lazy(() =>
    import('_Pages/mentor/schedule/MentorSchedule')
);

const MentorTimeSlot = lazy(() =>
    import('_Pages/mentor/schedule/MentorTimeSlot')
);

/* ---------- Notification ---------- */
const NotificationList = lazy(() =>
    import('_Pages/notification/NotificationList')
);

const AddNotification = lazy(() =>
    import('_Pages/notification/AddNotification')
);

const UpdateNotification = lazy(() =>
    import('_Pages/notification/UpdateNotification')
);

/* ---------- Overview (Intro) ---------- */
const OverviewIntro = lazy(() => import('_Pages/overview/intro/Intro'));

const UpdateOverviewIntro = lazy(() =>
    import('_Pages/overview/intro/UpdateIntro')
);

/* ---------- Overview (Analysis-Intro) ---------- */
const AnalysisIntro = lazy(() =>
    import('_Pages/overview/analysis-intro/AnalysisIntro')
);

const UpdateAnalysisIntro = lazy(() =>
    import('_Pages/overview/analysis-intro/UpdateAnalysisIntro')
);

/* ---------- Overview (AI-Analysis) ---------- */
const AIAnalysis = lazy(() => import('_Pages/overview/ai-analysis/AIAnalysis'));

const UpdateAIAnalysis = lazy(() =>
    import('_Pages/overview/ai-analysis/UpdateAIAnalysis')
);

/* ---------- Overview (Dial-a-Coach) ---------- */
const DialACoach = lazy(() =>
    import('_Pages/overview/dial-a-coach/DialACoach')
);
const UpdateDialACoach = lazy(() =>
    import('_Pages/overview/dial-a-coach/UpdateDialACoach')
);

const DialACoachAnalytics = lazy(() =>
    import('_Pages/overview/dial-a-coach/DialACoachAnalytics')
);
/* ---------- Overview (Free-Packs) ---------- */
const FreePacks = lazy(() => import('_Pages/overview/free-packs/FreePacks'));
const UpdateFreePacks = lazy(() =>
    import('_Pages/overview/free-packs/UpdateFreePacks')
);

/* ---------- Overview (Words) ---------- */
const WordsIntro = lazy(() => import('_Pages/overview/words/WordsList'));

const WordsUpdate = lazy(() => import('_Pages/overview/words/UpdateWords'));

const AddVideoForm = lazy(() => import('_Pages/overview/words/video/AddVideo'));

const UpdateVideoForm = lazy(() =>
    import('_Pages/overview/words/video/UpdateVideo')
);

/* ---------- Package ---------- */
const PackageList = lazy(() => import('_Pages/package/PackageList'));

const AddPackage = lazy(() => import('_Pages/package/AddPackage'));

const UpdatePackage = lazy(() => import('_Pages/package/UpdatePackage'));

/* ---------- Pass ---------- */
const PassList = lazy(() => import('_Pages/pass/PassList'));

const AddPass = lazy(() => import('_Pages/pass/AddPass'));

const UpdatePass = lazy(() => import('_Pages/pass/UpdatePass'));

/* ---------- Mentor Revenue Release ---------- */
const MentorRevenueInfo = lazy(() =>
    import('_Pages/revenue-release/MentorRevenueInfo')
);

const AddRevenueRelease = lazy(() =>
    import('_Pages/revenue-release/RevenueRelease')
);

/* ---------- Search User ---------- */
const SearchUser = lazy(() => import('_Pages/search-user'));

/* -------------------------------------------------- */
const IndexRouter = () => {
    return (
        <div>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {/* ----- Login ----- */}
                    <Route path="/login" exact>
                        <Login></Login>
                    </Route>

                    {/* ----- Dashboard ----- */}
                    <PrivateRoute
                        exact
                        component={Dashboard}
                        path="/dashboard"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Admin User ----- */}
                    <AdminRoute
                        exact
                        component={AdminUserList}
                        path="/admin-users"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddAdminUser}
                        path="/admin-user/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={UpdateAdminUser}
                        path="/admin-user/:userId/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AllAccessPassList}
                        path="/all-access"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddAllAccess}
                        path="/all-access/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={UpdateAllAccess}
                        path="/all-access/:id/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* -----Mentor Revenue Info  ----- */}
                    <SalesRoute
                        exact
                        component={MentorRevenueInfo}
                        path="/mentor/:mentorId/revenue-info"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddRevenueRelease}
                        path="/mentor/revenue/release"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Reset Password ----- */}
                    <PrivateRoute
                        exact
                        component={ResetPassForm}
                        path="/user/:userId/reset-pass"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Analytics Overview ----- */}
                    <PrivateRoute
                        exact
                        component={OverviewAnalytics}
                        path="/analytics"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Analytics (Sales) ----- */}
                    <PrivateRoute
                        exact
                        component={SalesIndex}
                        path="/sales"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={AIAnalytics}
                        path="/sales/ai"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={AllAccessAnalytics}
                        path="/sales/all-access"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={CouponAnalytics}
                        path="/analytics/coupon"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={CouponRedeemsData}
                        path="/analytics/coupon-redeems"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={CourseAnalytics}
                        path="/sales/course/:id"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={MentorAnalytics}
                        path="/sales/mentor/:id"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={PackageAnalytics}
                        path="/sales/package/:id"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <PrivateRoute
                        exact
                        component={PassAnalytics}
                        path="/sales/pass/:id"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Banner ----- */}

                    <AdminRoute
                        exact
                        component={BannerList}
                        path="/overview/banners"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddBanner}
                        path="/overview/banner/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={UpdateBanner}
                        path="/overview/banner/:bannerId/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Coupon ----- */}
                    <AdminRoute
                        exact
                        component={CouponBatchList}
                        path="/coupons"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={CouponList}
                        path="/coupons/batch/:batchId"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddCoupon}
                        path="/coupon/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={CouponBatchUpdate}
                        path="/coupons/batch/:batchId/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Course ----- */}
                    <PrivateRoute
                        exact
                        component={ViewCourses}
                        path="/courses"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddCourse}
                        path="/course/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={UpdateCourse}
                        path="/course/:courseSlug/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Form Responses ----- */}
                    <SupportRoute
                        exact
                        component={FormResponses}
                        path="/form-responses"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <SupportRoute
                        exact
                        component={ClickupForm}
                        path="/clickup-form"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Lesson ----- */}
                    <AdminRoute
                        exact
                        component={LessonList}
                        path="/course/:courseSlug/lessons/"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={AddLesson}
                        path="/course/:courseSlug/lesson/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateLesson}
                        path="/course/:courseSlug/lesson/:lessonId/update/"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Mentor ----- */}
                    <SalesRoute
                        exact
                        component={MentorList}
                        path="/mentors"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={AddMentor}
                        path="/mentor/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateMentor}
                        path="/mentor/:mentorId/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={MentorTimeSlot}
                        path="/mentor/:mentorId/schedule"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <MentorRoute
                        exact
                        component={MentorSchedule}
                        path="/schedule"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <MentorRoute
                        exact
                        component={MentorMeetings}
                        path="/schedule-manager"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Notification ----- */}
                    <AdminRoute
                        exact
                        component={NotificationList}
                        path="/notifications"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={AddNotification}
                        path="/notification/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateNotification}
                        path="/notification/:notifId/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Overview (Intro) ----- */}
                    <AdminRoute
                        exact
                        component={OverviewIntro}
                        path="/overview/intro"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateOverviewIntro}
                        path="/overview/intro/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Overview (Analysis-Intro) ----- */}
                    <AdminRoute
                        exact
                        component={AnalysisIntro}
                        path="/overview/analysis-intro"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateAnalysisIntro}
                        path="/overview/analysis-intro/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Overview (AI-Analysis) ----- */}
                    <AdminRoute
                        exact
                        component={AIAnalysis}
                        path="/overview/ai-analysis"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateAIAnalysis}
                        path="/overview/ai-analysis/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Overview (Dial-a-Coach) ----- */}
                    <AdminRoute
                        exact
                        component={DialACoach}
                        path="/overview/dial-a-coach"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateDialACoach}
                        path="/overview/dial-a-coach/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={DialACoachAnalytics}
                        path="/overview/dial-a-coach/analytics"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Overview (Free-Packs) ----- */}
                    <AdminRoute
                        exact
                        component={FreePacks}
                        path="/overview/free-packs"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={UpdateFreePacks}
                        path="/overview/free-packs/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Overview (Words) ----- */}
                    <AdminRoute
                        exact
                        component={WordsIntro}
                        path="/overview/words"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={WordsUpdate}
                        path="/overview/words/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <AdminRoute
                        exact
                        component={AddVideoForm}
                        path="/overview/words/add-video"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />
                    <PrivateRoute
                        exact
                        component={UpdateVideoForm}
                        path="/overview/words/edit-video"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Package ----- */}
                    <AdminRoute
                        exact
                        component={PackageList}
                        path="/packages"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddPackage}
                        path="/package/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={UpdatePackage}
                        path="/package/:packageSlug/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Pass ----- */}
                    <AdminRoute
                        exact
                        component={PassList}
                        path="/passes"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={AddPass}
                        path="/pass/add"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    <AdminRoute
                        exact
                        component={UpdatePass}
                        path="/pass/:id/update"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {/* ----- Search User ----- */}
                    <SupportRoute
                        exact
                        component={SearchUser}
                        path="/search-user"
                        isLoggedIn={rootStore.auth.loggedIn}
                    />

                    {rootStore.auth.loggedIn && <Redirect to="/dashboard" />}
                </Switch>
            </Suspense>
        </div>
    );
};

export default observer(IndexRouter);
