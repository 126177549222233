import api from './api.interface';
import BaseURL from '_Constants/url';

export const getPackages = async () => {
    try {
        const response = await api.get(BaseURL.API_PACKAGE);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addPackage = async (payload) => {
    try {
        const response = await api.post(BaseURL.API_PACKAGE, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updatePackage = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_PACKAGE, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const togglePackage = async (payload) => {
    try {
        const response = await api.put(
            `${BaseURL.API_PACKAGE}/active`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getFreePacks = async () => {
    try {
        const response = await api.get(`${BaseURL.API_PACKAGE}/free-packs`);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const upsertFreePacks = async (payload) => {
    try {
        const response = await api.put(
            `${BaseURL.API_PACKAGE}/free-packs`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
