import api from './api.interface';
import BaseURL from '_Constants/url';

export const getCoupons = async ({ pageNum = 1, numItems = 20 }) => {
    try {
        const response = await api.post(BaseURL.API_COUPON, {
            pageNum,
            numItems,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getCouponsByBatchId = async ({ pageNum, batch, numItems }) => {
    try {
        const response = await api.post(`${BaseURL.API_COUPON}/list`, {
            pageNum,
            batch,
            numItems,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addCoupon = async (payload) => {
    try {
        const response = await api.post(`${BaseURL.API_COUPON}/add`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateCoupon = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_COUPON, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const bulkUpdateCoupons = async (payload) => {
    try {
        const response = await api.put(
            `${BaseURL.API_COUPON}/bulk-edit`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getAnalytics = async (payload) => {
    try {
        const response = await api.post(
            `${BaseURL.API_COUPON}/analytics`,
            payload
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
