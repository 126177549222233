import api from './api.interface';
import BaseURL from '_Constants/url';

export const getPassList = async ({ pageNum = 1, numItems = 10 }) => {
    try {
        const response = await api.post(BaseURL.API_PASS, {
            pageNum,
            numItems,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getPassesByBatchId = async ({ pageNum, batch, numItems }) => {
    try {
        const response = await api.post(`${BaseURL.API_PASS}/list`, {
            pageNum,
            batch,
            numItems,
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const addPass = async (payload) => {
    try {
        const response = await api.post(`${BaseURL.API_PASS}/add`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const updatePass = async (payload) => {
    try {
        const response = await api.put(BaseURL.API_PASS, payload);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getProductsList = async () => {
    try {
        const response = await api.get(`${BaseURL.API_PASS}/products-list`);
        return response.data;
    } catch (error) {
        return error;
    }
};
