const Dashboard = () => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <h1>Welcome to The Cricuru, you are now logged in </h1>
        </div>
    );
};

export default Dashboard;
