import { makeAutoObservable } from 'mobx';
import { getConstants } from '_Services/constants.service';

export default class ConstantStore {
    constantsObj = {
        adminRole: {},
        analysisType: {},
        categories: {},
        codeKind: {},
        country: {},
        couponType: {},
        currency: {},
        currencySymbol: {},
        introKind: {},
        languages: {},
        lessonKind: {},
        mentorType: {},
        notifRedirect: {},
        productKind: {},
        purchaseFilter: {},
        ratioType: {},
        status: {},
        supportStatus: {},
    };

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getConstantsAction() {
        try {
            const res = await getConstants();
            res.success &&
                (this.constantsObj = Object.assign(
                    this.constantsObj,
                    res.data
                ));
        } catch (error) {
            return error;
        }
    }
}
